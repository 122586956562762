<template>
  <div class="navbarmobileFix">
  <section class="banner-two2 img curve">
    <div class="container-fluid text-center text-align">
      
      <div class="publicPageFirstSection">
        <div class="public-firstSectionText mt-4 d-none d-lg-block">
          <h1>For a Better Consultation Experience, <span  @click="betterExperience()"><a  href="#betterexperience"> 
            <font-awesome-icon :icon="['fas', 'question-circle']" style="font-size: 1.6vw;color: #000;" /></a></span> 
          </h1>
          <h1 >Choose Doctors Who Use RxIx  </h1>

          <h1 class=" line-space">For Inputs and Support In Your
            <span @click="healthcareSupport()"><a  href="#healthcare"> 
            <font-awesome-icon :icon="['fas', 'question-circle']" style="font-size: 1.6vw;color: #000;" /></a></span> 
          </h1>
          <h1>Health Care Journey, Join RxIx</h1>
        </div>
        <div class="publicfirstSectionIamge d-none d-lg-block">
          <img class="img-public" src="~@/assets/images/public-1.png" />
        </div>
      </div>
    </div>
  </section>
  <!-- first section only for mobile view -->
  <div class="publicPageFirstSection text-align text-center">
    <div class="container">
      <div class="public-firstSectionText mt-4 mb-4 d-block d-lg-none">
        <h1>For a Better</h1>
        <h1>Consultation</h1>
        <h1>Experience, <span  @click="betterExperience()"><a  href="#betterexperience"> 
            <font-awesome-icon :icon="['fas', 'question-circle']" class="questions-icon" /></a></span></h1>
        <h1 >Choose Doctors</h1>
        <h1>Who Use RxIx</h1>
        <div class="horizondal-white-line"></div>
        <h1 class=" line-space">For Inputs and</h1>
        <h1>Support To Your <span @click="healthcareSupport()"><a  href="#healthcare"> 
            <font-awesome-icon :icon="['fas', 'question-circle']" class="questions-icon" /></a></span> </h1>
        <h1 >Health Care</h1>
        <h1>Journey,</h1>
        <h1 >Join RxIx</h1>
      </div>
    </div>
  </div>
    <div class="text-align text-center">
      
      <div id="betterexperience" class="overlay"  v-show="showBetterConsultationTextImage">
        
        <div class="popup">
          <div class="popupbox" >
          <p class="closeButton mt-0 " style="text-align: end;" @click="closeBetterConsultation()"><font-awesome-icon :icon="['fas','times']" /></p>         
          <div class=" d-none d-lg-block">
          <fieldset class="RxixSupportToPublic" >
            <legend>Better patient-care experience</legend>
           
        <div class="sliderMobileView mt-2">
          <img src="~@/assets/images/educated-doctor.jpg" class="img-carousel-public">
          <p class="img-text-carousel-public-1  mb-0">RxIx offers doctors a library of resources to help them provide
            you good care.</p>
        </div>
        <div class="sliderMobileView mt-2 mx-2" >
          <img src="~@/assets/images/doctor-patient-consultation.jpg" class="img-carousel-public">
          <p class="img-text-carousel-public-2 mb-0">Doctors who use RxIx have access to a library of images to
            explain things better while offering consultation.
          </p>
        </div>
        <div class="sliderMobileView mt-2 ">
          <img src="~@/assets/images/doctor-consultant.png" class="img-carousel-public">
          <p class="img-text-carousel-public-3 mb-0">RxIx doctors update your medical records as part of the
            consultation. You own your records.
          </p>
        </div>
      </fieldset>
      </div>
      <div class="d-block d-lg-none">
        <div class="mobileborder ">
        <div class="py-2">
            <h3>Better patient-care experience</h3>
          </div>
    <div id="bettercare" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="5000">
      <img src="~@/assets/images/educated-doctor.jpg" class="img-carousel-public">
          <p class="img-text-carousel-public-1  mb-0">RxIx offers doctors a library of resources to help them provide
            you good care.</p>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img src="~@/assets/images/doctor-patient-consultation.jpg" class="img-carousel-public">
          <p class="img-text-carousel-public-2 mb-0">Doctors who use RxIx have access to a library of images to
            explain things better while offering consultation.
          </p>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img src="~@/assets/images/brain.jpg" class="img-carousel-public">
      <p class="img-text-carousel-public-3 mb-0">RxIx doctors update your medical records as part of the
            consultation. You own your records.
          </p>
    </div>
  </div>
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#bettercare" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#bettercare" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#bettercare" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
</div>
</div>
</div>
    </div>
      </div>
      </div>

      <div id="healthcare" class="overlay"  v-show="showHealthSupportTextImage">
	<div class="popup">
    <div class="popupbox" >
      <p class="closeButton mt-0 " style="text-align: end;" @click="closeHealthCare()"><font-awesome-icon :icon="['fas','times']" /></p>         
            <div class=" d-none d-lg-block">
      <fieldset class="RxixSupportToPublic" >
        <legend>All-round healthcare support</legend>
        <div class="sliderMobileView mt-2">
          <img src="~@/assets/images/online-communication.jpg" class="img-carousel-public">
          <p class="img-text-carousel-public-4 mb-0">Join patient support groups. Get answers to your queries.</p>
        </div>
        <div class="sliderMobileView mt-2 mx-2">
          <img src="~@/assets/images/health-journal.jpg" class="img-carousel-public">
          <p class="img-text-carousel-public-5 mb-0">Become a member of a doctor's online community and receive
            his/her wisdom.
          </p>
        </div>
        <div class="sliderMobileView mt-2">
          <img src="~@/assets/images/healthy-study.jpg" class="img-carousel-public">
          <p class="img-text-carousel-public-6 mb-0">Become health-literate. Access a free library of patient resources.
          </p>
        </div>

      </fieldset>
      </div>
      <div class="d-block d-lg-none">
        <div class="mobileborder">
        <div class="py-2">
            <h3>All-round healthcare support</h3>
          </div>
    <div id="healthcaresupport" class="carousel slide" data-bs-ride="carousel">
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="~@/assets/images/online-communication.jpg" data-bs-interval="5000" class="img-carousel-public">
          <p class="img-text-carousel-public-4 mb-0">Join patient support groups. Get answers to your queries.</p>
    </div>
    <div class="carousel-item">
      <img src="~@/assets/images/health-journal.jpg" data-bs-interval="5000" class="img-carousel-public">
          <p class="img-text-carousel-public-5 mb-0">Become a member of a doctor's online community and receive
            his/her wisdom.
          </p>
    </div>
    <div class="carousel-item">
      <img src="~@/assets/images/healthy-study.jpg" data-bs-interval="5000" class="img-carousel-public">
          <p class="img-text-carousel-public-6 mb-0">Become health-literate. Access a free library of patient resources.
          </p>
    </div>
  </div>
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#healthcaresupport" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#healthcaresupport" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#healthcaresupport" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
</div>
</div>
</div>
      </div>
      </div>
      </div>
    </div>

  <!-- </section> -->

  <!-- </div> -->
  <div class="cards-banner-public">
    <section class="banner-two2 img">
      <section>
        <div class="container cards-text-center d-none d-lg-block">
          <h1>What do you want to do today?</h1>
        </div>
        <div class="container cards-text-center d-block d-lg-none">
          <h1 class="mb-0">What do you want </h1>
          <h1>to do today?</h1>
        </div>
        <div class="join-group mt-5 pad">
          <div class="col-4 mobileViewCenter">
            <div class="card">
              <div class="card-body card-active">
                <a href="/public/searchclinic">
                  <div>
                    <img class="icon-img1-card" src="~@/assets/images/hospitallens.png" />
                  </div>
                  <p class="para-search-clinic mb-0 mt-3">
                    Search for clinics
                  </p>
                  <p class="para-search-clinic">
                    that use RxIx
                  </p>
                  <p class="card-text-public-1">
                    Consult with clinics and
                    doctors who have
                    chosen to offer you a
                    better experience.
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div class="col-4 mobileViewCenter">
            <div class="card">
              <div class="card-body card-active">
                <a href="/public/doctorcommunities">
                  <div>
                    <img class="icon-img1-card" src="~@/assets/images/man-man.png" />
                  </div>
                  <p class="parajoin mt-3">
                    Join a doctor's
                    online community
                  </p>
                  <p class="card-text-public-2">
                    Join a doctor's group. Get
                    answers to your medical
                    doubts.
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div class="col-4 mobileViewCenter">
            <div class="card">
              <div class="card-body card-active">
                <a href="/public/patientgroups">
                  <div>
                    <img class="icon-img1-card" src="~@/assets/images/community.png" />
                  </div>
                  <!-- <p class="para-join">
                   
                  </p> -->
                  <p class="para mt-3">
                    Join patient groups
                  </p>
                  <p class="card-text-public-3">
                    Get answers to your health
                    care queries. Know what's
                    working for others.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="join-group mt-5 mb-5">
        <div class="col-4 mobileViewCenter">
          <div class="card">
            <div class="card-body card-active">
              <a href="/public/patientresources">
                <div>
                  <img class="icon-img1-card" src="~@/assets/images/bookcreative.png" />
                </div>
                <p class="para mt-3">
                  Refer a library of
                  resources
                </p>
                <p class="card-text-public-4">
                  Become health-literate
                  Access resources form
                  reliable sources.
                </p>
              </a>
            </div>
          </div>
        </div>
                <div class="col-4 mobileViewCenter">
              <div class="card">
            <div class="card-body card-active" @click="signUpUser">
              <div>
                <img class="icon-img1-card" src="~@/assets/images/folder.png" />
              </div>
              <p class="para-request mt-3">
                Store
              </p>
              <p class="para">
                medical records
              </p>
              <p class="card-text-public-6">Maintain unlimited medical records of your family, secure and digitally.</p>
            </div>
            </div>
        </div>
        <div class="col-4 mobileViewCenter">
          <div class="card-item">
            <div class="container">
              <span class="comming-soon-text" v-bind:class="[showBetterConsultationTextImage||showHealthSupportTextImage ? 'hideImg' : '']">COMING SOON</span>
            </div>
            <div class="card-body">

              <!-- <a href="/public/patientresources"> -->
              <div>
                <img class="icon-img1-card" src="~@/assets/images/doubts-icon.png" />
              </div>
              <p class="para-request mt-3">
                Enquire
              </p>
              <p class="para">
                about a doctor
              </p>
              <p class="card-text-public-5">
                Speak to a patient who
                has consulted a doctor.
                Get feedback before you consult.
              </p>
              <!-- </a> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-4 mobileViewCenter">
            <div class="card-item ">
             
            <div class="container">
              <span class="comming-soon-text" v-bind:class="[showBetterConsultationTextImage||showHealthSupportTextImage ? 'hideImg' : '']">COMING SOON</span>
            </div>
            <div class="card-body">
              <a href="/public/requestconsultation">
              <div>
                <img class="icon-img1-card" src="~@/assets/images/hospitalplus.png" />
              </div>
              <p class="para-request mt-3">
                Request
              </p>
              <p class="para">
                home consultation
              </p>
              <p class="card-text-public-6">
                Can't visit a clinic?
                Get a doctor who uses RxIx
                to come home to
                provide you care.
              </p>
              </a>
            </div>
          </div>
        </div> -->


        <!-- <div class="col-2"></div> -->
      </div>
    </section>
  </div>
  <div class="container rx-wrapper pl-0 pr-0" style="background: #ffffff" id="public">
    <section class="text-color-white mt-5">
      <div class="container-fluid doc-text text-center">
        <div class="container costForYouText">
          <h1>What is the cost to you?</h1>
          <h3 class="costToYouText">No membership or annual fees. All current RxIx services are free for the public.</h3>

          <h3 class="mt-5 d-block d-lg-none costToYouText">Most RxIx services are free to the public. If there are premium
            services, you
            will be notified when you choose to avail the service.</h3>
        </div>
      </div>
    </section>
    <div class="container text-align text-center mt-5 mb-2">
      <div class="connect-text d-none d-lg-block">
        <h1 class="mb-0">Connect with RxIx Help Desk</h1>
      </div>
      <div class="connect-text d-block d-lg-none">
        <h1 class="mb-0">Connect with</h1>
        <h1>RxIx Help Desk</h1>
      </div>
      <div class="container call-whatsapp-content mt-3 mb-3">
        <img class="img-whatsapp-icon" src="@/assets/images/whatsapp-icon.png">
        <h1 class="whatsapp-number">82 960 13 460</h1>
      </div>
      <div class="d-none d-lg-block mt-4">
        <h3 class="mb-0">Need any support in your health care</h3>
        <h3>journey? Just Whatsapp us.</h3>

        <h3 class="mt-4 mb-0">Not a member of RxIx? No problem. You may</h3>
        <h3>still reach out to us.</h3>
      </div>
      <div class="d-block d-lg-none connectRxIxText">
        <h3 class="mb-0">Need any support in your</h3>
        <h3 class="mb-0">health care journey?</h3>
        <h3>Just Whatsapp us.</h3>
        
        <h3 class="mt-4 mb-0">Not a member of RxIx?</h3>
        <h3 class="mb-0">No problem. You may</h3>
        <h3>still reach out to us.</h3>
      </div>
    </div>
  </div>
</div>
<MobileNavbar></MobileNavbar>
</template>

<script>
import MobileNavbar from "../views/common-mobile-footer.vue"
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    MobileNavbar
  },
  data() {
    return {
      showBetterConsultationTextImage: false,
      showHealthSupportTextImage: false,
    };
  },
  methods: {
    signUpUser() {
           const publicMedicalPayload ={
            routeName : 'medi-records',
            card:'public',
            publicMediId:JSON.stringify(5)
           }
            this.$router.push({ path: '/signup', query: publicMedicalPayload });
        },
    healthcareSupport() {
      this.showHealthSupportTextImage = true
    },
    betterExperience() {
      this.showBetterConsultationTextImage = true;
    },
    closeBetterConsultation(){
this.showBetterConsultationTextImage=false
    },
    closeHealthCare(){
      this.showHealthSupportTextImage = false
    }
  },

});
</script>

<style>
.hideImg{
  z-index: -1;
}
.popupbox{
    background-color: #ffffff;
    padding: 20px;
    border-radius: 6px
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 
}

  .bottom-link-navbar{
    display: block !important;
  }
  li.nav-item.nav {
    display: block !important;
  }
.highlight{
  font-weight: bold;
}
fieldset.RxixSupportToPublic legend {
  padding: 4px 6px;
  margin: 0 auto;
  display: block;
  float: none;
  width: auto;
  font-size: 32px;
  border-radius: 5px;
}
div.connect-text {
  color: #000;
}

.call-whatsapp-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-whatsapp-icon {
  height: 75px;
  width: 75px;
  margin-right: 1rem;
}

.heading.text-space {
  margin-bottom: 0px;
  margin-top: 1.5rem;
}

.card-body.card-active {
  margin-top: 2.3rem;
}

span.comming-soon-text {
  font-size: 25px;
  background-color: #cd381c;
  color: #ffff;
  margin-top: 0;
  position: relative;
  bottom: 17px;
  padding: 10px;
  box-shadow: 2px 1px 6px 1px rgba(50, 50, 50, 0.75);
}

.horizondal-white-line {
  border-bottom: 2px solid #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 166px;
  margin-left: auto;
  margin-right: auto;
}

.costForYouText h1 {
  color: #000;
}


.publicPageFirstSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-text-carousel-public-1 {
  margin-top: 20px;
  width: 233px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 25px;
}

.img-text-carousel-public-2 {
  width: 265px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 25px;
}

.img-text-carousel-public-3 {
  width: 232px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 25px;
}

.img-text-carousel-public-4 {
  width: 174px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 25px;
}

.img-text-carousel-public-5 {
  width: 212px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 25px;
}

.img-text-carousel-public-6 {
  width: 169px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 25px;
}

.img-carousel-public {
  width: 300px;
  height:250px;
}

.nav-tabs {
  border-bottom: 0px !important;
}

.RxixSupportToPublic {
  padding: 0rem 1rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  border: 2px solid #00979e;
  border-radius: 10px;
  background-color: #fff;
}

.cards-banner-public {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #e5f4f5;
  background-image: url("~@/assets/images/bg-top.svg");
  background-repeat: no-repeat;
  background-size: 110%;
  /* padding-top: 90px; */
}



.join-group.mt-5.pad {
  width: 100%;
}

.join-group.mt-5 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}


.btn.btn-blue-color.text-white.btn-submit {
  float: right;
}

.col-lg-6.cards.card-body.row.col-12 {
  background-color: #e5f4f5;
  padding-top: 13px;
  padding-bottom: 10px;
  border-radius: 6px;
}

.form-groups label {
  height: 25px;
  font-size: 20px;
}


p.para {
  font-size: 25px;
  font-weight: bold;
  color: #2c3e50;
}
.para-search-clinic{
  font-size: 25px;
  font-weight: bold;
  color: #2c3e50;
  margin-left: 0px;
    margin-right: 0px;
    /* width: 250px; */
}

.para-join {
  font-size: 25px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 0px;
}

.para-request {
  font-size: 25px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 0px;
}

p.parajoin {
  font-size: 25px;
  font-weight: bold;
  color: #2c3e50;
  width: 242px;
  margin-left: auto;
  margin-right: auto;
}

.icon-img1-card {
  width: 100px;

}

.cards-text-center{
  text-align: center;
  margin-top: 10rem;
  color: #000;

}

p.card-text-public-1 {
  color: #59515e;
  font-size: 23px;
  width: 245px;
  margin: auto;
}

p.card-text-public-2 {
  color: #59515e;
  font-size: 23px;
  width: 249px;
  margin: auto;
}

p.card-text-public-3 {
  color: #59515e;
  font-size: 23px;
  width: 245px;
  margin: auto;
}

p.card-text-public-4 {
  color: #59515e;
  font-size: 23px;
  width: 233px;
  margin: auto;
}

p.card-text-public-5 {
  color: #59515e;
  font-size: 23px;
  width: 250px;
  margin: auto;
}

p.card-text-public-6 {
  color: #59515e;
  font-size: 23px;
  width: 243px;
  margin: auto;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  height: 380px;
}

.card {
  width: 18rem;
  border-color: #a0d4d8;
  border-width: medium;
  position: initial;
  box-shadow: 
  8px -8px 12px 0 rgba(0, 0, 0, 0.1),
    -12px 12px 16px rgba(255, 255, 255, 0.25);
    border-radius: 10px;
  cursor: pointer;
}

.card-item {
  background-color: #fff;
  border-radius: 10px;
  width: 18rem;
  border: 2px solid #a0d4d8 ;
  border-width: medium;
  position: initial;
}


.card-title-mt-3 {
  margin-top: 1.3rem !important;
  width: 100%;
  height: 45px;
}

.rxix-menu li.nav-item a.nav-link {
  color: #00979E;
}

.img-public {
  margin-top: 20px;
  width: 23.5vw;
  height: 28vw;
  margin-left: 30px;
  margin-right: 30px;
}

div.public-firstSectionText.mt-4 h1 {
  color: #2c3e50;
  margin-bottom: 0;
  font-size: 2vw;

}

.line-space {
  margin-top: 3vw;
}

section.banner-two2.img.curve {
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/better-consultation-banner2.jpg");
  background-size: 100% 100%;
  margin-top: 4.5rem;
}

.btn-box {
  border-color: #34989f !important;
}


@media only screen and (max-width:1200px) {
  .img-text-carousel-public-1 {
    width: 191px;
    text-align: center;
    font-size: 20px;
  }

  .img-text-carousel-public-2 {
    width: 213px;
    text-align: center;
    font-size: 20px;
  }

  .img-text-carousel-public-3 {
    width: 180px;
    text-align: center;
    font-size: 20px;
  }

  .img-text-carousel-public-4 {
    width: 173px;
    text-align: center;
    font-size: 20px;
  }

  .img-text-carousel-public-5 {
    width: 189px;
    text-align: center;
    font-size: 20px;
  }

  .img-text-carousel-public-6 {
    width: 138px;
    text-align: center;
    font-size: 20px;
  }


  .img-carousel-public {
    width: 225px;
    height: 200px;
  }
  .RxixSupportToPublic {
    padding: 0px 10px 10px 10px;
  }
}

@media screen and (max-width:992px) {
  .carousel-inner {
    width: min-content;
    height: 495px;
    margin: auto;
  }
  .img-carousel-public {
  width: 300px;
  height:250px;
}
  .img-text-carousel-public-1 {
    width: 233px;
  font-size: 25px;
}

.img-text-carousel-public-2 {
  width: 265px;
  font-size: 25px;
}

.img-text-carousel-public-3 {
  width: 232px;
  font-size: 25px;
}

.img-text-carousel-public-4 {
  width: 174px;
  font-size: 25px;
}

.img-text-carousel-public-5 {
  width: 212px;
  font-size: 25px;
}

.img-text-carousel-public-6 {
  width: 169px;
  font-size: 25px;
}
  .mobileborder{
    border: 2px solid #00979e;
  border-radius: 15px;
  padding: 10px;
  margin-top: 15px;
  }
  .carousel-indicators [data-bs-target] {
    background-color: #00979e;
    opacity: 0.2;
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-indicators {
    position: unset;
    border-radius: 50%;
  }
  fieldset.RxixSupportToPublic legend {
    font-size: 27px;
  }

  div.sliderMobileView {
    margin-top: 1rem;
  }
  .img-public {
    width: 33vw;
    height: 43vw;
  }
  .join-group.mt-5 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
  }

  div.col-4.mobileViewCenter {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cards-text-center h1 {
    font-size: 28px;
  }

  .costForYouText h1 {
    font-size: 28px;
  }

  div.connect-text h1 {
    font-size: 28px;
  }

  .costToYouText {
    font-size: 25px;
  }

  div.connectRxIxText h3 {
    font-size: 25px;
  }
}


@media screen and (max-width:991px) {
  .questions-icon{
   font-size: 30px; 
   color: #fff;
  }
  .whatsapp-number{
    font-size: 28px;
  }
  .navbarmobileFix{
    margin-bottom: 7rem;
  }
  div.public-firstSectionText.mt-4 h1 {
    color: #fff;
    font-size: 30px;
  }
  section.banner-two2.img.curve {
    margin-top: 0px;
  }

  .publicPageFirstSection {
    background-color: #00979E;
  }
}

@media screen and (max-width: 767px) {
  .cards-text-center {
    margin-top: 6rem;
  }
    .RxixSupportToPublic {
    display: inline-block;
  }
}

@media screen and (max-width: 704px) {
  .heading.text-space {
    margin-top: 1rem;
  }

}

@media screen and (max-width: 576px) {
  .carousel-inner {
    height: 370px;
  }
  .questions-icon{
   font-size: 25px; 
  }
  .mobileborder{
    margin-top: 10px;
  }
  .popupbox {
    padding: 10px;
  }
  .img-whatsapp-icon {
  height: 50px;
  width: 50px;
  margin-right: 1rem;
}
  .whatsapp-number{
    font-size: 23px;
  }
  .horizondal-white-line {
    border-bottom: 2px solid #fff;
    width: 145px;
    margin-left: auto;
    margin-right: auto;
  }

  div.public-firstSectionText.mt-4 h1 {
    font-size: 25px;
  }
  .costForYouText h1 {
    font-size: 23px;
  }

  div.connect-text h1 {
    font-size: 23px;
  }

  .costToYouText {
    font-size: 20px;
  }

  div.connectRxIxText h3{
    font-size: 20px;
  }

  .img-text-carousel-public-1 {
    width: 177px;
    text-align: center;
    font-size: 18px;
  }

  .img-text-carousel-public-2 {
    width: 209px;
    text-align: center;
    font-size: 18px;
  }

  .img-text-carousel-public-3 {
    width: 166px;
    text-align: center;
    font-size: 18px;
  }

  .img-text-carousel-public-4 {
    width: 157px;
    text-align: center;
    font-size: 18px;
  }

  .img-text-carousel-public-5 {
    width: 172px;
    text-align: center;
    font-size: 18px;
  }

  .img-text-carousel-public-6 {
    width: 128px;
    text-align: center;
    font-size: 18px;
  }

  .img-carousel-public {
    width: 220px;
    height: 180px;
  }

  fieldset.RxixSupportToPublic legend {
    font-size: 20px;
  }



  .cards-text-center h1 {
    font-size: 23px;
  }

  p.para {
    font-size: 20px;
  }
  .para-search-clinic{
    font-size: 20px;
    /* width: 200px; */
  }

  p.card-text-public-1 {
    font-size: 18px;
    width: 202px;
  }

  p.card-text-public-2 {
    font-size: 18px;
    width: 195px;
  }

  p.card-text-public-3 {
    font-size: 18px;
    width: 200px;
  }

  p.card-text-public-4 {
    font-size: 18px;
    width: 192px;
  }

  p.card-text-public-5 {
    font-size: 18px;
    width: 197px;
  }

  p.card-text-public-6 {
    font-size: 18px;
    width: 196px;
  }

  .card-body {
    height: 300px;
  }

  .para-request {
    font-size: 20px;
  }

  p.parajoin {
    font-size: 20px;
    width: 202px;
  }

  .para-request {
    font-size: 20px;
  }

  .card {
    width: 15rem;
  }
  .card-item {
    width: 15rem;
  }

  .icon-img1-card {
    width: 65px;
  }

  span.comming-soon-text {
    font-size: 20px;
  }
}
</style>